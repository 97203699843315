<style lang="scss" scoped>
// .box {
//   position: fixed;
//   right: 16px;
//   top: 128px;
//   z-index: 2;

//   .box-main {
//     width: 0px;
//     transition: 0.5s all;
//     background: rgba(255, 255, 255, 0.8);
//     border-radius: 8px;
//     // padding-bottom: 37px;
//     overflow: hidden;
//     .main {
//       .title {
//         margin-top: 14px;
//         margin-bottom: 15px;
//         // width: 96px;

//         margin-left: 16px;
//         // justify-content: right;
//         // height: 43px;
//         // display: flex;
//         // align-items: center;
//         // margin-top: 10px;
//         // margin-bottom: 18px;
//         font-size: 18px;
//         color: rgba(0, 0, 0, 0.8);
//         font-weight: bolder;
//         // margin-right: 16px;
//       }
//       .data-item {
//         display: flex;
//         align-items: center;
//         margin-bottom: 8px;
//         height: 40px;
//         .data {
//           line-height: 18px;
//           font-size: 13px;
//           color: rgba(0, 0, 0, 0.8);
//           margin-left: 16px;
//           white-space: nowrap;
//         }
//         .icon {
//           width: 30px;
//           height: 24px;
//           margin-left: 16px;
//         }
//       }
//       .data-item:last-child {
//         margin-bottom: 0;
//       }
//     }
//   }
// }

// .box-help {
//   width: 22px;
//   height: 56px;
//   position: absolute;
//   left: -22px;
//   top: 12px;
//   background-image: url("../../../../assets/features/hidden.svg");
//   cursor: pointer;
//   &#show {
//     background-image: url("../../../../assets/features/show.svg");
//   }
// }

// #showBox {
//   width: 353px;
//   padding-bottom: 32px;
// }

// #left0 {
//   right: 0;
// }
// .data-main {
//   max-height: calc(100vh - 144px - 24px - 53px - 37px - 54px);
//   overflow-y: auto;
// }
* {
  position: relative;
  z-index: 2;
}
.box {
  width: 293px;
  position: fixed;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  padding: 24px;
  padding-right: 10px;
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10px);
    border-radius: 16px;
  }
  .top-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: rgba(255, 255, 255, 0.8);
  }
  .top-line {
    width: 32px;
    height: 2px;
    background: #e60012;
    border-radius: 23px;
    margin: 8px 0;
  }
  .sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
  }
  .content-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 16px;
  }
}
</style>
<template>
  <div class="feature">
    <!-- <div class="box" :id="showBox ? '' : 'left0'">
            <div class="box-help"  @click="showBox=!showBox" :id="showBox?'':'show'"></div>
            <div class="box-main" :id="showBox?'showBox':''">
                <div class="main">
                    <div class="title">Features</div>
                    <div class="data-main">
                        <div class="data-item" v-for="item of tableDatas">
                        <img :src="item.img" class="icon" alt="">
                        <div class="datas">
                            <div class="data" v-for="i of item.datas">{{ i }}</div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="box" v-rc:left>
      <div class="top-title">ET G2 Series</div>
      <div class="top-line"></div>
      <div class="sub-title">
        6-15 KW | Three Phase | Up to 3 MPPTS | Hybrid Inverter (HV)
      </div>
      <div class="content-text">
        The ET G2 hybrid inverter is designed to maximise energy output, enhance
        self-consumption and facilitate extensive back-up power for homeowners.
        With power rating up to 15 kW, intelligent load controls and a wide
        battery voltage range, the inverter caters to individual needs. To
        secure a high level of energy autonomy, combine the hybrid inverter with
        GoodWe battery Lynx F G2, and connect the system to the GoodWe EV
        charger HCA and/or any smart-grid ready heat pump. By combining a
        variety of smart operation modes, the system can be optimized to further
        drive down energy cost.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBox: true,
    };
  },
  computed: {
    tableDatas() {
      let data = [
        {
          img: require("../../../../assets/features/1.svg"),
          datas: ["Integrated dry contact for external loads"],
        },
        {
          img: require("../../../../assets/features/2.svg"),
          datas: ["Peak shaving "],
        },
        {
          img: require("../../../../assets/features/3.svg"),
          datas: ["Integrated AFCI"],
        },
        {
          img: require("../../../../assets/features/4.svg"),
          datas: ["IP66 ingress protection"],
        },
        {
          img: require("../../../../assets/features/5.svg"),
          datas: ["Plug & Play"],
        },
        {
          img: require("../../../../assets/features/6.svg"),
          datas: ["Elegant and compact design"],
        },
        {
          img: require("../../../../assets/features/7.svg"),
          datas: ["Strong backup power supply"],
        },
        {
          img: require("../../../../assets/features/8.svg"),
          datas: ["Maximum 16 A DC input current per string"],
        },
      ];

      return data;
    },
  },
  created() {},
};
</script>
